@import "@angular/material/prebuilt-themes/indigo-pink.css";

/* Imported fonts */
@font-face {
  font-family: "BPreplay";
  src: url("./assets/fonts/BPreplay/BPreplay.eot");
  src: url("./assets/fonts/BPreplay/BPreplay.eot?#iefix") format("embedded-opentype"),
  url("./assets/fonts/BPreplay/BPreplay.woff") format("woff"),
  url("./assets/fonts/BPreplay/BPreplay.ttf") format("truetype"),
  url("./assets/fonts/BPreplay/BPreplay.svg#Flaticon") format("svg");
}

/* Colors */
:root {
  --primary: #0074e1;
  --secondary: #1b1f28;
  --tertiary: #ffffff;
  --danger: #dd0330;
}

/* Main Content */
body {
  color: var(--secondary);
  cursor: default;
  font-family: 'BPreplay', sans-serif;
  font-size: 14px;
  margin: 0;
}

@media screen and (max-width: 480px) {
  body {
    font-size: 12px;
  }
}
