@use '@angular/material' as mat;
@import '../node_modules/@angular/material/theming';

@include mat.core();

$primary: (
  50: #e0eefb,
  100: #b3d5f6,
  200: #80baf0,
  300: #4d9eea,
  400: #2689e6,
  500: #0074e1,
  600: #006cdd,
  700: #0061d9,
  800: #0057d5,
  900: #0044cd,
  A100: #f5f7ff,
  A200: #c2d2ff,
  A400: #8facff,
  A700: #7599ff,
  contrast: (
    50: black,
    100: black,
    200: black,
    300: black,
    400: black,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: black,
    A200: black,
    A400: black,
    A700: white
  )
);

$app-primary: mat.define-palette($primary, 600);
$app-accent: mat.define-palette($primary, 600);

$app-theme: mat.define-light-theme($app-primary, $app-accent, mat.define-palette(mat.$red-palette, 600));

@include mat.all-component-themes($app-theme);

$app-typography: mat.define-typography-config(
  $font-family: '"BPreplay", "Helvetica Neue", sans-serif'
);

@include mat.all-component-typographies($app-typography);

$background: map-get($app-theme, background);

.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button, .mat-flat-button, .mat-fab, .mat-mini-fab {
  font-weight: 900;
}

.mat-dialog-actions {
  justify-content: flex-end;
}

.mat-dialog-actions button:first-child {
  margin-right: 5px;
}

.mat-dialog-actions button:last-child {
  margin-left: 5px;
}

.mat-dialog-actions button:not(:first-child):not(:last-child) {
  margin: 0 5px;
}

.mat-dialog-title {
  align-items: center;
  display: flex !important;
  flex-direction: row;
  justify-content: space-between;
}

.mat-icon {
  vertical-align: middle;
}

.mat-icon-button:hover {
  background: mat.get-color-from-palette($background, 'hover');
}
